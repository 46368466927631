<template>
	<button
		:type="buttonType"
		:disabled="isDisabled"
		:class="[
			'relative transition-all duration-200 ease-in-out',
			{ 'cursor-progress' : isLoading },
			{ 'uppercase' : uppercase },
			hoverColor,
			buttonWidth,
			buttonHeight,
			buttonClasses,
			bgColor,
			textColor,
			textClasses,
			focusClasses,
			borderRadius,
			borderColor
		]"
		@click="handleButtonClick"
	>
		<slot>
			<span
				:class="[
					!showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
					hasSuccessText ? 'duration-100' : 'duration-0',
					'absolute w-full transition-all ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'
				]"
			>
				{{ buttonText }}
			</span>
			<span
				:class="[
					showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
					hasSuccessText ? 'duration-100' : 'duration-0',
					'absolute w-full transition-all ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'
				]"
			>
				{{ successText }}
			</span>

			<template v-if="isLoading">
				<LoadingSpinner
					:class="[
						borderRadius,
						bgColor,
						'absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full h-full p-2 opacity-75'
					]"
				/>
			</template>
		</slot>
	</button>
</template>

<script setup lang="ts">
// Reactivity Transforms
import { toRefs, computed } from 'vue'

// Props
const props = defineProps({
	disabled: {
		type: Boolean,
		default: true
	},
	isLoading: {
		type: Boolean,
		default: false
	},
	buttonWidth: {
		type: String,
		default: 'w-full'
	},
	buttonHeight: {
		type: String,
		default: 'h-11'
	},
	buttonClasses: {
		type: String,
		default: 'px-3'
	},
	borderRadius: {
		type: String,
		default: 'rounded-lg'
	},
	focusClasses: {
		type: String,
		default: 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-mx-orange focus:ring-opacity-80'
	},
	buttonText: {
		type: String,
		default: 'Submit'
	},
	successText: {
		type: String,
		default: ''
	},
	successfulResponse: {
		type: Boolean,
		default: false
	},
	textClasses: {
		type: String,
		default: 'font-bold'
	},
	hoverClasses: {
		type: String,
		default: ''
	},
	uppercase: {
		type: Boolean,
		default: false
	},
	variant: {
		type: String,
		default: 'primary',
		validator: (value: string) => {
			return [ 'primary', 'secondary' ].includes(value)
		}
	},
	submit: {
		type: Boolean,
		default: false
	},
	reset: {
		type: Boolean,
		default: false
	}
})
const { successfulResponse, successText, isLoading, disabled, variant, submit, reset, hoverClasses } = toRefs(props)

// Emits
const emit = defineEmits([ 'submit', 'click' ])

// Computed Vars
const bgColor = computed(() => {
	const color = variant.value === 'primary' ? 'bg-mx-orange' : 'bg-white'
	const loadingColor = variant.value === 'primary' ? 'bg-mx-orange-muted' : 'bg-gray-200'
	if (!disabled.value && isLoading.value) {
		return loadingColor
	}
	return color
})
const hoverColor = computed(() => {
	if (hoverClasses.value) {
		return hoverClasses.value
	}
	const color = variant.value === 'primary'
		? 'hover:bg-mx-orange-muted hover:border-mx-orange-muted'
		: 'hover:bg-mx-orange hover:border-mx-orange hover:text-white'
	return isDisabled.value ? 'cursor-not-allowed' : color
})
const textColor = computed(() => {
	return variant.value === 'primary' ? 'text-white' : 'text-black'
})
const borderColor = computed(() => {
	if (isDisabled.value) {
		return 'border-gray-300'
	}
	return variant.value === 'primary' ? 'border border-mx-orange' : 'border border-black'
})
const hasSuccessText = computed(() => {
	return successText.value.length
})
const showSuccessText = computed(() => {
	return successfulResponse.value && hasSuccessText.value
})
const isDisabled = computed(() => {
	return submit.value && disabled.value
})
const buttonType = computed(() => {
	if (reset.value) { return 'reset' }
	return submit.value ? 'submit' : 'button'
})

// Methods
function handleButtonClick (event: Event) {
	if (isLoading.value) {
		event.preventDefault()
	}
	submit.value ? emit('submit', event) : emit('click', event)
}
</script>
